import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Container from "../components/Container"
import BgImg from "../components/BgImg"

const Team = props => {
  const team = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Meet The Team" />
      <Container
        title="Meet The Team"
        style={{
          background: `#52575a`,
          color: `white`,
          position: "relative",
        }}
        whitetitle
      >
        {team.map(({ node }, index) => {
          const picture = node.frontmatter.picture.childImageSharp.fluid
          const name = node.frontmatter.title
          const role = node.frontmatter.role
          const body = node.rawMarkdownBody

          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-around",
                maxWidth: "1100px",
                zIndex: 5,
              }}
              key={index}
            >
              <Img
                fluid={picture}
                alt={name}
                style={{ display: "flex", width: "500px", maxHeight: "400px" }}
              />
              <div style={{ padding: "1rem 0" }}>
                <h4>{name}</h4>
                <h3>{role}</h3>
                <p>{body}</p>
              </div>
            </div>
          )
        })}
        <BgImg />
      </Container>
    </Layout>
  )
}

export default Team

export const teamListQuery = graphql`
  query teamListQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "team" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            role
            picture {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          rawMarkdownBody
        }
      }
    }
  }
`
